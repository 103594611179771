import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { Head } from '../components/templates/head';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { Button } from '../components/atoms/button';
import { PhoneNumber } from '../components/atoms/phone-number';

import LeftArrowIcon from '../assets/left-arrow.svg';
import RestartIcon from '../assets/restart.svg';

// A page for when there is an API error
const ErrorPage = ({ location }) => {
	const { errorMessage } = location?.state?.errorMessage || {
		errorMessage: '',
	};

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	return (
		<>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="flex flex-col min-safe-h-screen">
				<NextStepHeader
					title={
						errorMessage !== ''
							? errorMessage
							: `We've run into a technical error with your submission`
					}
					subtitle="Don't worry though, it just means you need to give us a call instead and we'll be happy to help."
					backBtn={
						<Button
							variant="White"
							className="!py-xxs-f !px-l-f"
							onClick={() => navigate(-1)}
						>
							<LeftArrowIcon className="mr-2 max-w-none" />
							Back
						</Button>
					}
					restartBtn={
						<Button
							variant="White"
							className="!py-xxs-f !px-l-f"
							onClick={() => navigate('/')}
						>
							<RestartIcon className="mr-2" />
							Restart
						</Button>
					}
				/>
				<div className="px-4 bg-white pb-7">
					<div className="wrapper-small">
						<PhoneNumber hasBorder={false} />
					</div>
				</div>
			</main>
		</>
	);
};

ErrorPage.defaultProps = {
	location: undefined,
};

ErrorPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export default ErrorPage;
